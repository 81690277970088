import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import './VideoCallView.css'; // Update styles here
import { Avatar, Button, Card, Container, Stack } from '@mui/material';
import {
  Call,
  CallEnd,
  MicOffOutlined,
  MicOutlined,
  Videocam,
  VideocamOff,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { socketCall as socket } from './socketChat';

export default function VideoCallView({
  receivedData,
  setVideoCallEnable,
  setCallType,
  setOpenCallConfirm,
  setCallState,
}) {
  const { userInfo } = useSelector((state) => state.userInfo);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [type, setType] = useState(receivedData.type);

  const otherUserId = useRef(null);

  const [localMicOn, setLocalMicOn] = useState(true);
  const [localWebcamOn, setLocalWebcamOn] = useState(true);

  const peerConnection = useRef(
    new RTCPeerConnection({
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
      ],
    })
  );

  const remoteRTCMessage = useRef(null);

  function connectSocketWithQuery(callerId) {
    socket.io.opts.query = { callerId }; // Set the query parameters
    socket.connect(); // Manually connect the socket
  }

  useEffect(() => {
    // Clean up socket events
    socket.on('callEnded', leave);
    socket.on('callAnswered', handleAnswer);
    socket.on('ICEcandidate', handleICECandidate);

    peerConnection.current.ontrack = (event) => {
      const remoteMediaStream = new MediaStream();
      remoteMediaStream.addTrack(event.track);
      setRemoteStream(remoteMediaStream);
    };

    peerConnection.current.onicecandidate = (event) => {
      if (event.candidate) {
        sendICECandidate({
          calleeId: otherUserId.current ?? receivedData.calleId,
          rtcMessage: event.candidate,
        });
      }
    };

    return () => {
      socket.off('callEnded', leave);
      socket.off('callAnswered', handleAnswer);
      socket.off('ICEcandidate', handleICECandidate);
    };
  }, []);

  useEffect(() => {
    if (receivedData) {
      // Call this function when you're ready to pass the query and connect
      connectSocketWithQuery(receivedData.callerId);
    }
  }, [receivedData]);

  useEffect(() => {
    if (
      receivedData.remoteRTCMessage &&
      receivedData.type === 'INCOMING_CALL'
    ) {
      remoteRTCMessage.current = receivedData.remoteRTCMessage;
      otherUserId.current = receivedData.calleeId;

      setType('INCOMING_CALL');
      setCallState('INCOMING_CALL');
    }
  }, [receivedData]);

  // function handleAnswer(data) {
  //   remoteRTCMessage.current = data.rtcMessage;
  //   peerConnection.current
  //     .setRemoteDescription(new RTCSessionDescription(data.rtcMessage))
  //     .then(() => {
  //       setType('WEBRTC_ROOM');
  //       setCallState('WEBRTC_ROOM');
  //     })
  //     .catch((err) => console.error('Error setting remote description:', err));
  // }

  function handleAnswer({ rtcMessage }) {
    peerConnection.current
      .setRemoteDescription(
        new RTCSessionDescription({ type: 'answer', sdp: rtcMessage })
      )
      .then(() => {
        setType('WEBRTC_ROOM');
        setCallState('WEBRTC_ROOM');
      })
      .catch((err) => console.error('Error setting remote description:', err));
  }
  function handleICECandidate(data) {
    if (peerConnection.current) {
      peerConnection.current
        .addIceCandidate(new RTCIceCandidate(data.rtcMessage))
        .catch((err) => console.error('Error adding ICE candidate:', err));
    }
  }

  function sendICECandidate(data) {
    socket.emit('ICEcandidate', data);
  }

  function processCall() {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream) => {
        setLocalStream(stream);
        stream
          .getTracks()
          .forEach((track) => peerConnection.current.addTrack(track, stream));

        peerConnection.current
          .createOffer()
          .then((offer) => peerConnection.current.setLocalDescription(offer))
          .then(() => {
            sendCall({
              calleeId: otherUserId.current ?? receivedData.calleId,
              rtcMessage: peerConnection.current.localDescription.sdp,
              profilePic: userInfo.profilePic,
              callType: 'VIDEO',
            });
          });
      })
      .catch((error) => console.error('Error in processCall:', error));
  }

  async function processAccept() {
    try {
      // Check if localStream is already available to avoid fetching it again
      if (!localStream) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: { width: { ideal: 1280 }, height: { ideal: 720 } },
        });
        setLocalStream(stream);

        // Add tracks to the peer connection only if they aren't already added
        stream.getTracks().forEach((track) => {
          if (
            !peerConnection.current
              .getSenders()
              .find((sender) => sender.track === track)
          ) {
            peerConnection.current.addTrack(track, stream);
          }
        });
      }

      // Set the WebRTC state to indicate the call is in progress
      setType('WEBRTC_ROOM');
      setCallState('WEBRTC_ROOM');

      // Ensure remote offer is set before creating the answer
      await peerConnection.current.setRemoteDescription(
        new RTCSessionDescription(remoteRTCMessage.current)
      );

      // Now create the answer after remote description is successfully set
      const answer = await peerConnection.current.createAnswer();
      await peerConnection.current.setLocalDescription(answer);

      // Emit the answer to the caller
      answerCall({
        callerId: otherUserId.current ?? receivedData.calleId,
        rtcMessage: answer,
      });
    } catch (error) {
      console.error('Error in processAccept:', error);
    }
  }

  function answerCall(data) {
    socket.emit('answerCall', data);
  }

  function sendCall(data) {
    socket.emit('call', data);
  }

  function toggleCamera() {
    setLocalWebcamOn(!localWebcamOn);
    localStream
      .getVideoTracks()
      .forEach((track) => (track.enabled = !localWebcamOn));
  }

  function toggleMic() {
    setLocalMicOn(!localMicOn);
    localStream
      .getAudioTracks()
      .forEach((track) => (track.enabled = !localMicOn));
  }

  function leave() {
    const data = { participantId: otherUserId.current ?? receivedData.calleId };
    socket.emit('endCall', data);
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
      setLocalStream(null);
    }
    peerConnection.current.close();
    setType('JOIN');
    setCallState('JOIN');
    setVideoCallEnable(false);
    setCallType('');
    setOpenCallConfirm(false);
  }

  // Render different views based on call state
  if (type === 'OUTGOING_CALL') {
    return (
      <Card
        className="calling"
        elevation={5}
        sx={{
          width: '300px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '15px',
        }}
      >
        <h2>Calling {receivedData.name}...</h2>
        <img
          src={`https://soapboxapi.megahoot.net/profile-pictures/${receivedData.profilePic}`}
          alt="Profile"
          className="profile-pic"
        />
        <div className="spinner"></div>
        <button className="end-call-button" onClick={leave}>
          End Call
        </button>
      </Card>
    );
  }

  if (type === 'JOIN') {
    return (
      <div
        style={{
          width: '300px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h2>Call {receivedData.name}</h2>
        <Avatar
          src={`https://soapboxapi.megahoot.net/profile-pictures/${receivedData.profilePic}`}
          alt="Profile"
          sx={{ width: '150px', height: '150px' }}
        />
        <Stack direction="row" gap={2} my={5}>
          <Button
            variant="contained"
            color="error"
            sx={{ borderRadius: '100%', padding: '1.1rem' }}
            onClick={leave}
          >
            <CallEnd />
          </Button>
          <Button
            variant="contained"
            color="success"
            sx={{ borderRadius: '100%', padding: '1.1rem' }}
            onClick={() => {
              otherUserId.current = receivedData.calleeId;
              setType('OUTGOING_CALL');
              setCallState('OUTGOING_CALL');
              processCall();
            }}
          >
            <Videocam />
          </Button>
        </Stack>
      </div>
    );
  }

  if (type === 'WEBRTC_ROOM') {
    return (
      <Card
        className="video-container"
        elevation={5}
        sx={{ bgcolor: '#212121' }}
      >
        <video
          autoPlay
          muted
          className="local-video"
          ref={(video) => {
            if (video && localStream) video.srcObject = localStream;
          }}
        ></video>
        {remoteStream && (
          <video
            autoPlay
            className="remote-video"
            ref={(video) => {
              if (video) video.srcObject = remoteStream;
            }}
          ></video>
        )}
        <div className="controls">
          <button onClick={toggleMic} className="control-button">
            {localMicOn ? <MicOutlined /> : <MicOffOutlined />}
          </button>
          <button onClick={toggleCamera} className="control-button">
            {localWebcamOn ? <Videocam /> : <VideocamOff />}
          </button>
          <button onClick={leave} className="end-call-button">
            <CallEnd />
          </button>
        </div>
      </Card>
    );
  }

  if (type === 'INCOMING_CALL') {
    return (
      <Container maxWidth="lg">
        <div className="incoming-call">
          <h2>{receivedData.name} is video calling you...</h2>
          <Avatar
            src={`https://soapboxapi.megahoot.net/profile-pictures/${receivedData.profilePic}`}
            alt="Profile"
            sx={{ width: '200px', height: '200px' }}
          />
          <div className="action-buttons">
            <button className="accept-button" onClick={() => processAccept()}>
              <Call />
            </button>
            <button className="reject-button" onClick={() => leave()}>
              <CallEnd />
            </button>
          </div>
        </div>
      </Container>
    );
  }

  return null;
}
