import { MoreVert, Visibility } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MarkdownPreview from '@uiw/react-markdown-preview';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RepeatIcon from '@mui/icons-material/Repeat';

function IndividualPostOverlay({ postData }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [postDataStyles, setPostDataStyles] = useState(null);

  useEffect(() => {
    if (postData) {
      const fontFamilyStyleRaw =
        postData?.fontFamilyStyle && postData.fontFamilyStyle.includes('{')
          ? JSON.parse(postData.fontFamilyStyle)
          : null;
      setPostDataStyles(fontFamilyStyleRaw);
    }
  }, [postData]);

  return (
    <Card
      id={`feed-item-${postData?.id}`}
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        maxWidth: isMobile ? '100%' : '720px',
        margin: '0 auto',
        borderRadius: '20px',
        position: 'relative',
        backgroundColor: '#fff',
        padding: '1px',
      }}
    >
      {postData?.mimeType?.includes('video') ||
      postData?.mimeType?.includes('audio') ? (
        <CardMedia
          component="video"
          loading="lazy"
          src={`https://soapboxapi.megahoot.net/images/${postData.image}`}
          controls
          sx={{
            width: isMobile ? '100%' : '360px',
            height: isMobile ? '70vh' : '640px',
            objectFit: 'contain',
            bgcolor: '#000',
          }}
          muted
          onMouseEnter={(e) => e.target.play()}
          poster={postData?.mimeType?.includes('audio') && `/audio.png`}
        />
      ) : postData?.mimeType?.includes('image') ? (
        <CardMedia
          component="img"
          loading="lazy"
          image={`https://soapboxapi.megahoot.net/images/${postData.image}`}
          alt="feed"
          sx={{
            width: isMobile ? '100%' : '360px',
            height: isMobile ? '70vh' : '640px',
            objectFit: 'contain',
            bgcolor: '#000',
          }}
        />
      ) : (
        <CardContent
          style={{
            padding: '0px',
            height: isMobile ? '40vh' : '70vh',
            minWidth: isMobile ? '100%' : '360px',
            width: isMobile ? '100%' : '360px',
          }}
        >
          <MarkdownPreview
            source={postData.caption}
            style={{
              padding: 4,
              wordWrap: 'break-word',
              width: '100%',
              overflow: 'auto',
              height: '100%',
              color: postDataStyles?.color,
              backgroundColor: postDataStyles?.bgColor,
            }}
          />
        </CardContent>
      )}

      <CardContent
        sx={{
          flex: isMobile ? '1' : '0',
          width: isMobile ? '100%' : '360px',
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1,
            maxWidth: isMobile ? '100%' : '100%',
          }}
        >
          <Avatar
            src={`https://soapboxapi.megahoot.net/profile-pictures/${postData.authorProfilePic}`}
          />

          <Typography
            variant="h6"
            component="a"
            sx={{
              textDecoration: 'none',
              fontSize: isMobile ? '1rem' : '1.2rem',
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {postData.authorUsername}
            {postData.verified === 1 ? (
              <>
                {postData?.badge === 'Premium' ? (
                  <img
                    src="/PremiumVerified.svg"
                    height="18px"
                    width="18px"
                    alt="premium_verified"
                  />
                ) : postData?.badge === 'Corporate' ? (
                  <img
                    src="/PurpleVerified.svg"
                    height="18px"
                    width="18px"
                    alt="corporate_verified"
                  />
                ) : postData?.badge === 'Notable' ? (
                  <img
                    src="/PurpleVerified.svg"
                    height="18px"
                    width="18px"
                    alt="corporate_verified"
                  />
                ) : postData?.badge === 'General' ? (
                  <img
                    src="/PurpleVerified.svg"
                    height="18px"
                    width="18px"
                    alt="corporate_verified"
                  />
                ) : postData?.badge === 'Media' ? (
                  <img
                    src="/MediaVerified.svg"
                    height="18px"
                    width="18px"
                    alt="corporate_verified"
                  />
                ) : (
                  <img
                    src="/RegularVerified.svg"
                    height="18px"
                    width="18px"
                    alt="regular_verified"
                  />
                )}
              </>
            ) : null}
          </Typography>
          <Button
            sx={{
              border: `1px solid ${theme.palette.primary.contrastText}`,
              color: theme.palette.primary.contrastText,
              fontSize: '10px',
              padding: '2px',
            }}
          >
            Follow
          </Button>
        </Box>
        {postData.mimeType ? (
          <Typography variant="body2" my={1}>
            {postData.caption}
          </Typography>
        ) : null}
      </CardContent>
      <CardContent
        sx={{
          position: 'absolute',
          bottom: isMobile ? '40%' : '30%',
          left: isMobile ? '85%' : '310px',
          backgroundColor: '#0000004d',
          color: theme.palette.primary.contrastText,
          width: isMobile ? '40px' : '50px',
          borderRadius: '30px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton sx={{ flexDirection: 'column' }} color="primary">
            <ThumbUpIcon sx={{ color: theme.palette.primary.contrastText }} />
            <Typography
              variant="body2"
              sx={{ ml: 0.5, color: theme.palette.primary.contrastText }}
            >
              {postData.likes}
            </Typography>
          </IconButton>
          <IconButton sx={{ flexDirection: 'column' }} color="primary">
            <Visibility sx={{ color: theme.palette.primary.contrastText }} />
            <Typography
              variant="body2"
              sx={{ ml: 0.5, color: theme.palette.primary.contrastText }}
            >
              {postData.views}
            </Typography>
          </IconButton>
          <IconButton sx={{ flexDirection: 'column' }}>
            <RepeatIcon sx={{ color: theme.palette.primary.contrastText }} />
            <Typography
              variant="body2"
              sx={{ ml: 0.5, color: theme.palette.primary.contrastText }}
            >
              {postData.reposts}
            </Typography>
          </IconButton>
          <IconButton>
            <MoreVert sx={{ color: theme.palette.primary.contrastText }} />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
}

export default IndividualPostOverlay;
