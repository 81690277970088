import { useTheme } from '@emotion/react';
import {
  Done,
  DoneAll,
  MoreVertOutlined,
  Edit,
  Reply,
  AddReaction,
  Delete,
} from '@mui/icons-material';
import { IconButton, Typography, Menu, MenuItem, Box } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Emojione } from 'react-emoji-render';
import axios from 'axios';
import EmojiPicker from 'emoji-picker-react';

function MessageCard({
  message,
  handleDeleteChat,
  setEditingMessage,
  editingMessage,
  replyEnable,
  setReplyEnable,
  setEditEnable,
  setNewMessage,
  handleQuicReply,
}) {
  const theme = useTheme();
  const { userInfo } = useSelector((state) => state.userInfo);
  const [isHovered, setIsHovered] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEmojiList, setShowEmojiList] = useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => {
    setIsHovered(false);
    setShowPopup(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isOwnMessage = message.user._id === userInfo.username;
  const backgroundColor = isOwnMessage
    ? theme.palette.primary.light
    : '#e6e6e6';
  const textColor = isOwnMessage ? '#fff' : '#000';

  const mediaContent = (message) => {
    if (message.text.match(/\.(gif|png|jpg|jpeg|eps)$/i)) {
      return (
        <img
          src={message.text.replace('http://', 'https://')}
          alt="mchathive"
          style={{
            maxWidth: '300px',
            objectFit: 'contain',
            borderRadius: '8px',
            maxHeight: '360px',
          }}
          draggable={false}
        />
      );
    }
    if (message.text.match(/\.(mp3|wav)$/i)) {
      return (
        <audio
          src={message.text.replace('http://', 'https://')}
          controls
          style={{ width: '100%', maxWidth: '360px', maxHeight: '360px' }}
        />
      );
    }
    if (message.text.match(/\.(mp4|mov|wmv|webm|avi)$/i)) {
      return (
        <video
          src={message.text.replace('http://', 'https://')}
          controls
          style={{
            width: '100%',
            borderRadius: '8px',
            maxWidth: '360px',
            maxHeight: '360px',
          }}
          draggable={false}
        />
      );
    }
    return null;
  };

  const reactionEmojis = ['👍', '❤️', '😂', '😮'];

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: 'relative',
        maxWidth: '70%',
        minWidth: '200px',
        background: backgroundColor,
        padding: '10px 15px',
        borderRadius: '20px',
        wordBreak: 'break-word',
        cursor: 'pointer',
      }}
      draggable={false}
    >
      {message.replyParent && (
        <Box
          sx={{
            bgcolor: 'grey',
            color: '#000',
            borderRadius: '8px',
          }}
        >
          {mediaContent(message.replyParent) || (
            <Typography
              variant="body2"
              style={{
                fontWeight: 500,
                color: '#000',
              }}
            >
              <Emojione text={message?.replyParent?.text} />
            </Typography>
          )}

          <Typography
            variant="caption"
            style={{
              display: 'block',
              marginTop: 5,
              textAlign: 'right',
              color: textColor,
            }}
          >
            {new Date(message.replyParent.createdAt).toLocaleTimeString()}
          </Typography>
        </Box>
      )}
      {message.quickReply && (
        <div
          style={{
            position: 'absolute',
            bottom: '-20px',
            backgroundColor: '#f0f0f0',

            borderRadius: '100%',
            width: '30px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {message.quickReply}
        </div>
      )}
      {mediaContent(message) || (
        <Typography
          variant="body2"
          style={{
            fontWeight: 500,
            color: textColor,
          }}
        >
          <Emojione text={message.text} />
        </Typography>
      )}

      <Typography
        variant="caption"
        style={{
          display: 'block',
          marginTop: 5,
          textAlign: 'right',
          color: textColor,
        }}
      >
        {new Date(message.createdAt).toLocaleTimeString()}
      </Typography>
      {message.seen ? (
        <DoneAll fontSize="small" color="inherit" />
      ) : (
        <Done fontSize="small" color="inherit" />
      )}

      {/* Popup for Reactions and Options */}
      {isHovered && (
        <div
          style={{
            position: 'absolute',
            top: '-40px',
            right: '-10px',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            padding: '5px',
            zIndex: 10,
          }}
          onMouseEnter={() => setShowPopup(true)}
          onMouseLeave={() => setShowPopup(false)}
        >
          {/* Quick Reactions */}
          {reactionEmojis.map((emoji, index) => (
            <IconButton
              key={index}
              size="small"
              onClick={() => {
                setEditingMessage(message);
                handleQuicReply(emoji, message);
              }}
            >
              <span style={{ fontSize: '16px' }}>{emoji}</span>
            </IconButton>
          ))}

          {/* Add More Reactions */}
          <IconButton
            size="small"
            onClick={() => {
              setShowEmojiList((prev) => !prev);
            }}
          >
            <AddReaction fontSize="small" />
          </IconButton>

          {/* Reply Action */}
          <IconButton
            size="small"
            onClick={() => {
              setReplyEnable(true);
              setEditingMessage(message);
            }}
          >
            <Reply fontSize="small" />
          </IconButton>

          {/* More Options */}
          <IconButton size="small" onClick={handleMenuOpen}>
            <MoreVertOutlined fontSize="small" />
          </IconButton>
        </div>
      )}

      {showEmojiList && (
        <div
          style={
            isOwnMessage
              ? {
                  position: 'absolute',
                  top: '0px',
                  right: '40%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                  padding: '5px',
                  zIndex: 10,
                }
              : {
                  position: 'absolute',
                  top: '0px',
                  left: '40%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  backgroundColor: '#fff',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                  padding: '5px',
                  zIndex: 10,
                }
          }
          onMouseEnter={() => setShowEmojiList(true)}
          onMouseLeave={() => setShowEmojiList(false)}
        >
          <EmojiPicker
            onEmojiClick={({ emoji }) => {
              setEditingMessage(message);
              handleQuicReply(emoji, message);
              setShowEmojiList(false);
            }}
          />
        </div>
      )}

      {/* More Options Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            handleDeleteChat(message); /* Handle delete */
          }}
        >
          <Delete fontSize="small" style={{ marginRight: '8px' }} /> Delete
          Message
        </MenuItem>
        <MenuItem
          onClick={() => {
            setEditEnable(true);
            setEditingMessage(message);
            setNewMessage(message?.text);
          }}
        >
          <Edit fontSize="small" style={{ marginRight: '8px' }} /> Edit Message
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose(); /* Handle more actions */
          }}
        >
          <MoreVertOutlined fontSize="small" style={{ marginRight: '8px' }} />{' '}
          More Actions
        </MenuItem>
      </Menu>
    </div>
  );
}

export default MessageCard;
