import React, { useEffect } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { useRouter } from '../components/hooks';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setChatDataList, updateUser } from '../redux/reducers/userSlice';

function LoadingScreen() {
  const dispatch = useDispatch();
  const { userInfo, chatData, chatListData } = useSelector(
    (state) => state.userInfo
  );
  const router = useRouter();
  const fetchuserData = (username) => {
    axios
      .get(`https://soapboxapi.megahoot.net/user/${username}`)
      .then((res) => {
        // setUser(res.data[0]);
        if (res.data) {
          dispatch(updateUser(res.data[0]));
          // navigation.navigate('Home', { data: res.data });
          //saveUserProfile(JSON.stringify(res.data));
          router.push('/home');
        }
      })
      .catch((err) => console.log(err));
  };
  const fetchChatDataList = (username) => {
    axios
      .post('https://soapboxapi.megahoot.net/upload/getChatDataPrivateinbox', {
        to: username,
      })
      .then((res) => {
        const payload = res.data.sort(
          (b, a) =>
            new Date(a.chat.timestamp).getTime() -
            new Date(b.chat.timestamp).getTime()
        );
        dispatch(setChatDataList(payload));
      });
  };
  useEffect(() => {
    const existingTokenraw = localStorage.getItem('mchathive-auth-token');
    if (existingTokenraw && existingTokenraw !== '') {
      const tokenRow = JSON.parse(existingTokenraw);

      fetchChatDataList(tokenRow.username);
      fetchuserData(tokenRow.username);
    } else {
      router.push('/login');
    }
  }, []);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <CircularProgress />
    </Box>
  );
}

export default LoadingScreen;
