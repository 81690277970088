import React from 'react';
import { Box, Typography, Link, Grid } from '@mui/material';

const Footer = () => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="column"
    >
      <Grid
        sx={{
          backgroundColor: '#3d215b',
          padding: '1rem',
        }}
        xs={10}
        width="100%"
        direction="row"
        container
        justifyContent="center"
      >
        <Link
          href="#"
          sx={{ color: '#fff', mx: 2, textDecoration: 'none' }} // Link style
        >
          © 2024 MegaHoot. All Rights Reserved.
        </Link>
        <Link
          href="https://www.mchathive.net/mchathive-privacy-policy/"
          sx={{ color: '#fff', mx: 2, textDecoration: 'none' }} // Link style
        >
          Privacy Policy
        </Link>
        <Link
          href="https://www.mchathive.net/mchathive-terms-of-service/"
          sx={{ color: '#fff', mx: 2, textDecoration: 'none' }} // Link style
        >
          Terms & Conditions
        </Link>
      </Grid>
    </Grid>
  );
};

export default Footer;
