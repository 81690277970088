/* eslint-disable import/no-extraneous-dependencies */
// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const workSpaceslice = createSlice({
  name: 'workspace',
  initialState: {
    userInfo: null,
    token: null,
    workSpaces: [],
  },
  reducers: {
    addWorkspace: (state, action) => {
      if (state.workSpaces.length > 0) {
        const updatedWorkspaces = [...state.workSpaces, action.payload];
        state.workSpaces = updatedWorkspaces;
        // const localStoragePayload = JSON.stringify(updatedWorkspaces);
        // localStorage.setItem('monyble-workspaces', localStoragePayload);
      } else {
        const updatedWorkspaces = [action.payload];
        state.workSpaces = updatedWorkspaces;
        // const localStoragePayload = JSON.stringify(updatedWorkspaces);
        // localStorage.setItem('monyble-workspaces', localStoragePayload);
      }
    },
    addAllWorkspace: (state, action) => {
      state.workSpaces = action.payload;
    },

    updateWorkspace: (state, action) => {
      const workSpaces = state.workSpaces.map((e) => {
        if (e.id === action.payload.id) {
          return { ...e, ...action.payload };
        }
        return e;
      });
      console.log(workSpaces, action.payload);
      state.workSpaces = workSpaces;
      // localStorage.setItem('monyble-workspaces', JSON.stringify(workSpaces));
    },
    deleteWorkspace: (state, action) => {
      const existingWorkspaceIndex = state.workSpaces.findIndex(
        (e) => e._id === action.payload._id
      );

      if (existingWorkspaceIndex !== -1) {
        const workSpaces = state.workSpaces.filter((e) => e._id !== action.payload._id);
        state.workSpaces = workSpaces;
        // const localStoragePayload = JSON.stringify(workSpaces);
        // localStorage.setItem('monyble-workspaces', localStoragePayload);
      }
    },
  },
});

export const { addWorkspace, updateWorkspace, deleteWorkspace, addAllWorkspace } =
  workSpaceslice.actions;
export default workSpaceslice.reducer;
