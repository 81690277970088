import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  ListItem,
  IconButton,
  TextField,
  Button,
  InputAdornment,
  useMediaQuery,
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  Stack,
} from '@mui/material';
import {
  ArrowBack,
  MoreVert,
  Call,
  Send,
  Mic,
  AttachFile,
  Videocam,
  CloseOutlined,
  Delete,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { setChatDataList } from '../../redux/reducers/userSlice';
import { useRouter } from '../../components/hooks';
import MessageCard from './messageCard';
import { v4 as uuidv4 } from 'uuid';
import socket, { startSocket } from './socketChat';
import Feeds from './feeds';
import { Link } from 'react-router-dom';
import VideoCallView from './videoCall';
import AudioCallView from './audioCall';
import MiniProgramsCatalogue from './miniPrograms/miniProgramsCatalogue';
import { toast } from 'react-toastify';
import PublicProfilePage from './publicProfilePage';

function ChatRoom({
  selectedChat,
  setSelectedChat,
  messages,
  setMessages,
  setTabValue,
  setIframeSrc,
  setShowLeft,
  showLeft,
}) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { userInfo, fcmToken } = useSelector((state) => state.userInfo);
  const [showProfile, setShowProfile] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1); // Track page state for pagination
  const [openCallConfirm, setOpenCallConfirm] = useState(false);
  const [callType, setCallType] = useState(''); // 'audio' or 'video'
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [videoCallEnable, setVideoCallEnable] = useState(false);
  const [audioCallEnable, setAudioCallEnable] = useState(false);
  const [callState, setCallState] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isEphemeral, setIsEphemeral] = useState(false);
  const [isFollowing, setFollowing] = useState(true);
  const messagesEndRef = useRef(null); // For scrolling to the bottom
  const chatContainerRef = useRef(null); // For detecting scroll to top
  const fileInputRef = useRef(null);
  const [replyEnable, setReplyEnable] = useState(false);
  const [editEnable, setEditEnable] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Process the selected files (images, videos, audio) as needed

    uploadFileUri(file);
    event.target.value = ''; // Reset file input after selection
  };

  const handleAttachClick = () => {
    fileInputRef.current.click(); // Trigger the file input
  };

  const fetchSelectedChatData = (page) => {
    setLoading(true);
    axios
      .post('https://soapboxapi.megahoot.net/upload/getChatDataPrivate', {
        to: selectedChat.chatFrom,
        from: selectedChat.chatTo,
        page: page,
        pageSize: 100,
      })
      .then((res) => {
        const data = res.data.map((e) => ({
          _id: e.threadId,
          text: e.chat.message,
          createdAt: e.chat.timestamp,
          seen: e.seen,
          replyParent: e.chat?.replyParent,
          quickReply: e.chat?.quickReply,
          user: {
            _id: e.chatFrom,
            name: e.chatFrom,
            avatar: `https://soapboxapi.megahoot.net/profile-pictures/${e.chat.profilePic}`,
          },
        }));
        if (page === 1) {
          setMessages(data);
        } else {
          setMessages((prevMessages) => [...data, ...prevMessages]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpenCallConfirm = (type) => {
    setCallType(type);
    setOpenCallConfirm(true);

    if (type === 'audio') {
      setAudioCallEnable(true);
    } else {
      setVideoCallEnable(true);
    }
  };

  const handelDeleteAll = () => {
    const deleteAction = async () => {
      await axios.post('https://soapboxapi.megahoot.net/upload/deleteChatAll', {
        roomname: selectedChat.chatFrom + userInfo.username,
      });

      setMessages([]);
    };

    toast.promise(deleteAction, {
      pending: 'Deleting all messages',
      success: 'All messages deleted ',
      error: 'Please try again',
    });
  };

  const handleCloseCallConfirm = () => {
    setOpenCallConfirm(false);
    setVideoCallEnable(false);
    setAudioCallEnable(false);
  };

  const handleConfirmCall = () => {
    // Add your logic to initiate the call
    handleCloseCallConfirm();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEdit = (message) => {
    setEditingMessage(message);
  };
  const handleSaveEdit = (editedText) => {
    setMessages((prevMessages) =>
      prevMessages.map((m) =>
        m._id === editingMessage._id ? { ...m, text: editedText } : m
      )
    );
    setEditingMessage((prev) => ({ ...prev, text: editedText }));
  };
  const confirmEditedMessage = async () => {
    const handleEditMessage = async () => {
      handleSaveEdit(newMessage);
      await axios.post('https://soapboxapi.megahoot.net/upload/editChat', {
        from: editingMessage.user._id,
        threadId: editingMessage._id,
        newMessage: newMessage,
      });
      setEditingMessage(null);
      setEditEnable(false);
      setNewMessage('');
    };

    toast.promise(handleEditMessage, {
      pending: `Editing message`,
      success: `Message edited successfully`,
      error: 'Please try again',
    });
  };

  const handleReply = () => {
    if (!newMessage) {
      return;
    }
    if (
      typeof newMessage === 'undefined' ||
      typeof newMessage === 'object' ||
      typeof newMessage === 'function' ||
      typeof newMessage === 'boolean'
    ) {
      return;
    }
    let id = uuidv4();
    let today = new Date();

    const message = newMessage;

    const payload = {
      threadId: id,
      to: selectedChat.chatFrom,
      from: userInfo.username,
      isClub: 0,
      isPrivate: 1,
      isCommunity: 0,
      name: selectedChat.name,
      message: message,
      replyParent: editingMessage,
      profilePic: userInfo?.profilePic,
      isEmoji: isEmoji(message),
      timestamp: today.toISOString(),
      ephemeral: isEphemeral ? true : false,
      deviceToken: fcmToken ?? 'fcmToken', // fcmToken is of the user to whom message is going to be dleivered
    }; // Assuming the user sends the message

    const newMessagesData = [
      {
        _id: id,
        createdAt: today.toUTCString(),
        text: newMessage,
        user: {
          _id: userInfo.username,
          avatar: `https://soapboxapi.megahoot.net/profile-pictures/${userInfo.profilePic}`,
          name: userInfo.name,
        },

        sent: true,
        received: false,
        ephemeral: isEphemeral,
        replyParent: editingMessage,
      },
    ];
    socket.emit('private-message-soapbox', payload);
    setMessages((prev) => {
      // Check if newMessagesData is defined and has at least one message
      if (newMessagesData && newMessagesData.length > 0) {
        return [newMessagesData[0], ...prev];
      }
      return prev; // Return previous state if no new messages
    });

    setNewMessage('');
    setReplyEnable(false);
    setEditingMessage(null);
    fetchChatDataList(userInfo?.username);
  };

  const handleQuicReplySocket = (data) => {
    socket.emit('private-message-soapbox-reply', {
      messagePayload: data,
      to: selectedChat.chatFrom,
      from: userInfo.username,
    });
  };

  const handleQuicReply = (editedText, editingMessage) => {
    axios
      .post('https://soapboxapi.megahoot.net/upload/editChat', {
        from: editingMessage.user._id,
        threadId: editingMessage._id,
        quickReply: editedText,
        newMessage: editingMessage.text,
      })
      .then((res) => {
        if (res.data && res.data.message) {
          const socketPayload = { ...editingMessage, quickReply: editedText };

          handleQuicReplySocket(socketPayload);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setEditingMessage(null));
    setMessages((prevMessages) =>
      prevMessages.map((m) =>
        m._id === editingMessage._id
          ? {
              ...m,
              quickReply: editedText,
            }
          : m
      )
    );
    setEditingMessage(null);
  };

  const handleMessageRequest = () => {
    const messageRequest = async () => {
      // return new Promise((resolve) => {
      //   setTimeout(() => {
      //     resolve('success');
      //   }, 1000); // 1 seconds
      // });

      await axios.post(
        `https://soapboxapi.megahoot.net/upload/messageRequest`,
        {
          to: selectedChat.chatFrom,
          from: userInfo?.username,
          status: 'pending',
        }
      );
    };

    toast.promise(messageRequest, {
      pending: `Sending message request to ${selectedChat.chatFrom}`,
      success: `Message request sent to ${selectedChat.chatFrom} successfully`,
      error: 'Please try again',
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function isEmoji(str) {
    var ranges = [
      '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])', // U+1F680 to U+1F6FF
    ];

    if (str?.match(ranges.join('|'))) {
      return true;
    } else {
      return false;
    }
  }

  const uploadFileUri = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const uploadDataToast = async () => {
        const uploadResponse = await axios.post(
          'https://soapboxapi.megahoot.net/upload/uploadFile',
          formData
        );

        if (uploadResponse) {
          const imageUrl = uploadResponse.data;

          handleSocketEmit(
            `https://soapboxapi.megahoot.net/storageChat/${imageUrl.imageUrl}`
          );
        } else {
          console.error('Failed to upload image');
        }
      };

      toast.promise(uploadDataToast, {
        pending: 'Sending Media...',
        success: 'Media Sent Successful',
        error: 'Please try again',
      });
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };
  const handleSocketEmit = (newMessage) => {
    if (replyEnable) {
      handleReply();
    } else if (editEnable) {
      confirmEditedMessage();
    } else {
      let id = uuidv4();
      let today = new Date();

      const message = newMessage;

      const payload = {
        threadId: id,
        to: selectedChat.chatFrom,
        from: userInfo.username,
        isClub: 0,
        isPrivate: 1,
        isCommunity: 0,
        name: selectedChat.name,
        message: message,
        replyParent: '',
        profilePic: userInfo?.profilePic,
        isEmoji: isEmoji(message),
        timestamp: today.toISOString(),
        ephemeral: isEphemeral ? true : false,
        deviceToken: fcmToken ?? 'fcmToken', // fcmToken is of the user to whom message is going to be dleivered
      }; // Assuming the user sends the message

      const newMessagesData = [
        {
          _id: id,
          createdAt: today.toUTCString(),
          text: newMessage,
          user: {
            _id: userInfo.username,
            avatar: `https://soapboxapi.megahoot.net/profile-pictures/${userInfo.profilePic}`,
            name: userInfo.name,
          },

          sent: true,
          received: false,
          ephemeral: isEphemeral,
        },
      ];
      socket.emit('private-message-soapbox', payload);
      setMessages((prev) => {
        // Check if newMessagesData is defined and has at least one message
        if (newMessagesData && newMessagesData.length > 0) {
          return [newMessagesData[0], ...prev];
        }
        return prev; // Return previous state if no new messages
      });

      setNewMessage('');
      fetchChatDataList(userInfo?.username);
    }
  };

  const handleSocketTyping = () => {
    socket.emit('typing', {
      to: selectedChat.chatFrom,
      from: userInfo?.username,
    });
  };

  const fetchChatDataList = (username) => {
    axios
      .post('https://soapboxapi.megahoot.net/upload/getChatDataPrivateinbox', {
        to: username,
      })
      .then((res) => {
        const payload = res.data.sort(
          (b, a) =>
            new Date(a.chat.timestamp).getTime() -
            new Date(b.chat.timestamp).getTime()
        );

        dispatch(setChatDataList(payload));
      });
  };

  const handleDeleteChat = (editingMessage) => {
    const deleteAction = async () => {
      await axios.post('https://soapboxapi.megahoot.net/upload/deleteChat', {
        from: userInfo.username,
        threadId: editingMessage._id,
      });

      setMessages((prevMessages) =>
        prevMessages.filter((m) => m._id !== editingMessage._id)
      );
    };

    toast.promise(deleteAction, {
      pending: 'Deleting Message',
      success: 'Message Deleted ',
      error: 'Please try again',
    });
  };

  const isChatDisabled = () => {
    if (isFollowing) {
      return false;
    }

    if (!isFollowing && messages && messages.length === 0) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (userInfo && selectedChat) {
      const handlePrivateChat = (data) => {
        if (
          data.to === userInfo.username &&
          data.from === selectedChat.chatFrom
        ) {
          const sentMessage = {
            _id: data.threadId,
            text: data.message,
            ephemeral: data.ephemeral,
            createdAt: data.timestamp,
            replyParent: data?.replyParent,
            isPecuKey: data?.isPecuKey,
            user: {
              _id: data.from,
              name: data.chatFrom,
              avatar: `https://soapboxapi.megahoot.net/profile-pictures/${data.profilePic}`,
            },
          };

          setMessages((prev) => {
            return [sentMessage, ...prev];
          });

          socket.emit('seen', {
            to: selectedChat.chatFrom,
            from: userInfo.username,
            ...sentMessage,
            seen: 1,
          });
        }
      };

      const handleSeen = (data) => {
        if (
          data.to === userInfo.username &&
          data.from === selectedChat.chatFrom
        ) {
          setMessages((prevMessages) =>
            prevMessages.map((e) => {
              if (e._id === data._id) {
                return { ...e, seen: 1 }; // Update the message with seen status
              }
              return e; // Return other messages unchanged
            })
          );
        }
      };

      const handleTyping = ({ to, from }) => {
        if (to === userInfo.username && from === selectedChat.chatFrom) {
          setIsTyping(true);
          const typingTimeout = setTimeout(() => {
            setIsTyping(false);
          }, 10000);

          // Clear the timeout when the 'typing' event is received again
          const clearTypingTimeout = () => {
            clearTimeout(typingTimeout);
            setIsTyping(false);
          };

          socket.once('typing', ({ to: nextTo, from: nextFrom }) => {
            if (
              nextTo !== userInfo.username ||
              nextFrom !== selectedChat.chatFrom
            ) {
              clearTypingTimeout();
            }
          });
        }
      };

      // Register socket event listeners
      socket.on('receive-private-chat-soapbox', handlePrivateChat);
      socket.on('seen', handleSeen);
      socket.on('typing', handleTyping);
      // socket.on('received-private-message-soapbox-reply', data => {
      //   const {messagePayload, to, from} = data;
      //   if (data.to == chatTo && data.from == chatFrom) {
      //     setIncomingEmojiReply(messagePayload);
      //   }
      // });
      // Fetch user status
      fetchUserStatus(selectedChat?.chatFrom);

      // Cleanup function to remove event listeners
      return () => {
        socket.off('receive-private-chat-soapbox', handlePrivateChat);
        socket.off('seen', handleSeen);
        socket.off('typing', handleTyping);
      };
    }
  }, [userInfo, selectedChat]);

  const fetchUserStatus = async (username) => {
    try {
      const followersResSender = await axios.get(
        `https://soapboxapi.megahoot.net/user/followers/${username}`
      );
      const followersResReceiver = await axios.get(
        `https://soapboxapi.megahoot.net/user/followers/${userInfo.username}`
      );

      const isFollowingUser =
        followersResSender.data.some(
          (e) => e.followedBy === userInfo.username
        ) && followersResReceiver.data.some((e) => e.followedBy === username);

      setFollowing(isFollowingUser);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (selectedChat && selectedChat.chatFrom) {
      fetchSelectedChatData(page);
    }
  }, [selectedChat]);

  return (
    <Grid
      item
      xs={12}
      sm={12} // Occupies 2/3rd of the screen on larger devices
      style={{
        borderLeft: '1px solid #ddd',
        height: '100vh',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Card
        style={{
          height: '100%',
          borderRadius: 0,
          display: 'flex',
          flexDirection: 'column',
          //background: selectedChat ? '#fff' : 'url(/bg.png)',
          width: '100%',
        }}
      >
        {selectedChat ? (
          <>
            <CardContent
              style={{
                padding: '10px 20px',
                borderBottom: '1px solid #ddd',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {isMobile && (
                <IconButton
                  onClick={() => {
                    setSelectedChat(null);
                    setShowLeft(true);
                  }}
                >
                  <ArrowBack />
                </IconButton>
              )}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  onClick={() => {
                    setSelectedProfile(selectedChat);
                    setShowProfile(true);
                  }}
                >
                  <Avatar
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      marginRight: 12,
                    }}
                    src={`https://soapboxapi.megahoot.net/profile-pictures/${selectedChat?.profilePic}`}
                  >
                    {selectedChat.name?.slice(0, 1)}
                  </Avatar>
                </Box>
                <Box
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.primary.main,
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    onClick={() => {
                      setSelectedProfile(selectedChat);
                      setShowProfile(true);
                    }}
                  >
                    {selectedChat.name}
                  </Typography>
                  {!isFollowing && (
                    <Button
                      variant="outlined"
                      onClick={handleMessageRequest}
                      sx={{ ml: '10px' }}
                      size="small"
                    >
                      Message Request
                    </Button>
                  )}
                </Box>
              </div>
              <div>
                {showLeft ? (
                  <>
                    <IconButton onClick={() => handleOpenCallConfirm('audio')}>
                      <Call />
                    </IconButton>
                    <IconButton onClick={() => handleOpenCallConfirm('video')}>
                      <Videocam />
                    </IconButton>
                    <IconButton onClick={() => handelDeleteAll('video')}>
                      <Delete />
                    </IconButton>
                  </>
                ) : null}
                <IconButton onClick={handleClick}>
                  <MoreVert />
                </IconButton>
              </div>
            </CardContent>

            <div
              ref={chatContainerRef}
              style={{
                flex: 1,
                overflowY: 'auto',
                padding: '20px 20px 0 20px',
                backgroundColor: '#f9f9f9',
                display: 'flex',
                flexDirection: 'column-reverse',
                width: '100%',
                marginBottom: '150px',
              }}
            >
              <Box
                sx={{
                  minHeight: '30px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {isTyping && (
                  <img src="/typing.gif" width="100px" alt="typing" />
                )}
              </Box>
              {messages && messages.length > 0 ? (
                messages?.map((message, index) => (
                  <ListItem
                    key={index}
                    style={{
                      display: 'flex',
                      justifyContent:
                        message?.user?._id === userInfo.username
                          ? 'flex-end'
                          : 'flex-start',
                    }}
                  >
                    <MessageCard
                      message={message}
                      handleDeleteChat={handleDeleteChat}
                      setEditingMessage={setEditingMessage}
                      editingMessage={editingMessage}
                      replyEnable={replyEnable}
                      editEnable={editEnable}
                      setEditEnable={setEditEnable}
                      setReplyEnable={setReplyEnable}
                      setNewMessage={setNewMessage}
                      handleQuicReply={handleQuicReply}
                    />
                  </ListItem>
                ))
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight="100%"
                >
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <Typography>
                      This is the start of your connversation
                    </Typography>
                  )}
                </Box>
              )}
            </div>

            <CardContent
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '2px 0px 2px 0px',
                position: 'fixed',
                bottom: 0,
                backgroundColor: '#fff',
                width: showLeft ? '70%' : '99%',
              }}
            >
              <Stack sx={{ width: '100%' }}>
                {replyEnable && editingMessage && (
                  <Box
                    sx={{
                      bgcolor: '#f0f0f0',
                      padding: '1rem',
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    <IconButton
                      sx={{ position: 'absolute', right: '10px' }}
                      onClick={() => {
                        setReplyEnable(false);
                        setEditingMessage(null);
                      }}
                    >
                      <CloseOutlined color="error" />
                    </IconButton>

                    <MessageCard
                      message={editingMessage}
                      handleDeleteChat={handleDeleteChat}
                      setEditingMessage={setEditingMessage}
                      editingMessage={editingMessage}
                      replyEnable={replyEnable}
                      setReplyEnable={setReplyEnable}
                      editEnable={editEnable}
                      setEditEnable={setEditEnable}
                    />
                  </Box>
                )}

                <TextField
                  onFocusCapture={() => handleSocketTyping()}
                  fullWidth
                  variant="outlined"
                  size="small"
                  sx={{ input: { py: '1rem' } }}
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') handleSocketEmit(newMessage);
                  }}
                  disabled={isChatDisabled()}
                  placeholder={
                    isFollowing
                      ? 'Type a message'
                      : 'You can not send message anymore'
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton disabled={isChatDisabled()}>
                          <Mic />
                        </IconButton>
                        <IconButton
                          disabled={isChatDisabled()}
                          onClick={handleAttachClick}
                        >
                          <AttachFile />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleSocketEmit(newMessage)}
                          disabled={!newMessage.trim()}
                        >
                          <Send />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
                <input
                  type="file"
                  accept="image/*,video/*,audio/*" // Accepts images, videos, and audio files
                  multiple
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: 'none' }} // Hide the file input
                />
              </Stack>
            </CardContent>
          </>
        ) : (
          <MiniProgramsCatalogue
            setTabValue={setTabValue}
            setIframeSrc={setIframeSrc}
            setShowLeft={setShowLeft}
          />
        )}
      </Card>

      {/* Call confirmation dialog */}
      <Dialog
        open={videoCallEnable}
        onClose={() => {
          return;
        }}
        fullWidth={callState === 'WEBRTC_ROOM' ? true : false}
        maxWidth={callState === 'WEBRTC_ROOM' ? 'lg' : '300px'}
      >
        {videoCallEnable && (
          <VideoCallView
            receivedData={{
              profilePic: selectedChat.profilePic,
              name: selectedChat.name,
              calleId: selectedChat.chatFrom,
              callerId: userInfo.username,
              fcmToken: selectedChat.fcmToken,
              type: 'JOIN',
            }}
            setVideoCallEnable={setVideoCallEnable}
            setCallType={setCallType}
            setOpenCallConfirm={setOpenCallConfirm}
            setCallState={setCallState}
          />
        )}
      </Dialog>

      <Dialog
        open={audioCallEnable}
        onClose={() => {
          return;
        }}
        fullWidth={callState === 'WEBRTC_ROOM' ? true : false}
        maxWidth={callState === 'WEBRTC_ROOM' ? 'sm' : '300px'}
      >
        {audioCallEnable && (
          <AudioCallView
            receivedData={{
              profilePic: selectedChat.profilePic,
              name: selectedChat.name,
              calleId: selectedChat.chatFrom,
              callerId: userInfo.username,
              fcmToken: selectedChat.fcmToken,
              type: 'JOIN',
            }}
            setAudioCallEnable={setAudioCallEnable}
            setCallType={setCallType}
            setOpenCallConfirm={setOpenCallConfirm}
            setCallState={setCallState}
            ChatHeader={() => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0.8rem',
                  backgroundColor: '#3d215b',
                }}
              >
                <Box
                  onClick={() => {
                    setSelectedProfile(selectedChat);
                    setShowProfile(true);
                  }}
                >
                  <Avatar
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      marginRight: 12,
                    }}
                    src={`https://soapboxapi.megahoot.net/profile-pictures/${selectedChat?.profilePic}`}
                  >
                    {selectedChat.name?.slice(0, 1)}
                  </Avatar>
                </Box>

                <Box
                  onClick={() => {
                    setSelectedProfile(selectedChat);
                    setShowProfile(true);
                  }}
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.primary.main,
                  }}
                >
                  <Typography variant="h6" style={{ fontWeight: 600 }}>
                    {selectedChat.name}
                  </Typography>
                </Box>
              </div>
            )}
          />
        )}
      </Dialog>

      <Dialog
        maxWidth="xl"
        open={showProfile}
        fullWidth={true}
        onClose={() => setShowProfile(false)}
      >
        <PublicProfilePage username={selectedProfile?.chatFrom} />
      </Dialog>
    </Grid>
  );
}

export default ChatRoom;
