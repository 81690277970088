/* eslint-disable import/no-extraneous-dependencies */
// counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: null,
    token: null,
    chatData: [],
    chatListData: [],
    discoverData: [],
    fcmToken: null,
  },

  reducers: {
    addToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('mchathive-auth-token', action.payload);
    },
    updateUser: (state, action) => {
      state.userInfo = action.payload;
    },
    setMostActiveChatData: (state, action) => {
      state.chatData = state.chatData
        ? {
            ...state.chatData,
            [action.payload.chatFrom]: action.payload.chatData,
          }
        : { [action.payload.chatFrom]: action.payload.chatData };
    },
    setChatDataList: (state, action) => {
      return {
        ...state,
        chatListData: action.payload,
      };
    },
    setDiscoverData: (state, action) => {
      return {
        ...state,
        discoverData: action.payload,
      };
    },

    logOut: (state) => {
      localStorage.removeItem('mchathive-auth-token');
      state.userInfo = null;
    },
  },
});

export const {
  addToken,
  logOut,
  updateUser,
  setChatData,
  setChatDataList,
  setDiscoverData,
} = userSlice.actions;
export default userSlice.reducer;
