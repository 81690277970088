import React, { useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import './VideoCallView.css'; // Update styles here
import { Avatar, Button, Card, Container, Stack } from '@mui/material';
import {
  Call,
  CallEnd,
  CallMade,
  CallReceived,
  Camera,
  CameraAlt,
  MicOffOutlined,
  MicOutlined,
  Videocam,
  VideocamOff,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';

export default function AudioCallView({
  receivedData,
  setAudioCallEnable,
  setCallType,
  setOpenCallConfirm,
  ChatHeader,
  setCallState,
}) {
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [type, setType] = useState(receivedData.type);
  const [callerId] = useState(receivedData.callerId);
  const otherUserId = useRef(null);
  const { userInfo, fcmToken } = useSelector((state) => state.userInfo);

  const socket = io('https://testrandom.megahoot.net', {
    transports: ['websocket', 'polling'],
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 2000,
    query: {
      callerId,
    },
  });

  const [localMicOn, setLocalMicOn] = useState(true);
  const [localWebcamOn, setLocalWebcamOn] = useState(true);

  const peerConnection = useRef(
    new RTCPeerConnection({
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
      ],
    })
  );

  const remoteRTCMessage = useRef(null);

  useEffect(() => {
    // socket.on('newCall', (data) => {
    //   remoteRTCMessage.current = data.rtcMessage;
    //   otherUserId.current = data.callerId;
    //   setType('INCOMING_CALL');
    // });

    socket.on('callEnded', () => {
      leave();
    });

    socket.on('callAnswered', (data) => {
      remoteRTCMessage.current = data.rtcMessage;

      peerConnection.current
        .setRemoteDescription(new RTCSessionDescription(data.rtcMessage))
        .catch((err) => console.log('Error setting remote description:', err));
      setType('WEBRTC_ROOM');
      setCallState('WEBRTC_ROOM');
    });

    socket.on('ICEcandidate', (data) => {
      const message = data.rtcMessage;
      if (peerConnection.current) {
        peerConnection.current
          .addIceCandidate(new RTCIceCandidate(message))
          .catch((err) => console.log('Error adding ICE candidate:', err));
      }
    });

    peerConnection.current.ontrack = (event) => {
      const remoteMediaStream = new MediaStream();
      remoteMediaStream.addTrack(event.track);
      setRemoteStream(remoteMediaStream);
    };

    peerConnection.current.onicecandidate = (event) => {
      if (event.candidate) {
        sendICEcandidate({
          calleeId: otherUserId.current ?? receivedData.calleId,
          rtcMessage: event.candidate,
        });
      }
    };

    return () => {
      socket.off('newCall');
      socket.off('callAnswered');
      socket.off('ICEcandidate');
    };
  }, []);

  useEffect(() => {
    // remoteRTCMessage.current = data.rtcMessage;
    // otherUserId.current = data.callerId;
    // setType('INCOMING_CALL');
    if (
      receivedData.remoteRTCMessage &&
      receivedData.type === 'INCOMING_CALL'
    ) {
      remoteRTCMessage.current = receivedData.remoteRTCMessage;
      otherUserId.current = receivedData.calleId;

      setType('INCOMING_CALL');
    }
  }, [receivedData]);

  function sendICEcandidate(data) {
    socket.emit('ICEcandidate', data);
  }

  async function processCall() {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
      .then(async (stream) => {
        setLocalStream(stream);
        stream
          .getTracks()
          .forEach((track) => peerConnection.current.addTrack(track, stream));

        const sessionDescription = await peerConnection.current.createOffer();
        await peerConnection.current.setLocalDescription(sessionDescription);
        sendCall({
          calleeId: otherUserId.current ?? receivedData.calleId,
          rtcMessage: sessionDescription,
          deviceToken: receivedData.fcmToken,
          profilePic: userInfo.profilePic,
          callType: 'AUDIO',
        });
      });
  }

  async function processAccept() {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
      .then(async (stream) => {
        setLocalStream(stream);
        stream
          .getTracks()
          .forEach((track) => peerConnection.current.addTrack(track, stream));

        setType('WEBRTC_ROOM');
        setCallState('WEBRTC_ROOM');
        await peerConnection.current.setRemoteDescription(
          new RTCSessionDescription(remoteRTCMessage.current)
        );
        const sessionDescription = await peerConnection.current.createAnswer();
        await peerConnection.current.setLocalDescription(sessionDescription);
        answerCall({
          callerId: otherUserId.current ?? receivedData.calleId,
          rtcMessage: sessionDescription,
        });
      });
  }

  function answerCall(data) {
    socket.emit('answerCall', data);
  }

  function sendCall(data) {
    socket.emit('call', data);
  }

  function toggleCamera() {
    setLocalWebcamOn(!localWebcamOn);
    localStream
      .getVideoTracks()
      .forEach((track) => (track.enabled = !localWebcamOn));
  }

  function toggleMic() {
    setLocalMicOn(!localMicOn);
    localStream
      .getAudioTracks()
      .forEach((track) => (track.enabled = !localMicOn));
  }

  function leave() {
    const data = { participantId: otherUserId.current ?? receivedData.calleId };
    socket.emit('endCall', data);
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
      setLocalStream(null);
    }
    peerConnection.current.close();

    setType('JOIN');
    setCallState('JOIN');
    setAudioCallEnable(false);
    setCallType('');
    setOpenCallConfirm(false);
  }

  return (
    <>
      {type === 'JOIN' && (
        <Card
          elevation={5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '15px',
            width: '300px',
          }}
        >
          <h2> Call {receivedData.name}</h2>
          <Avatar
            src={`https://soapboxapi.megahoot.net/profile-pictures/${receivedData.profilePic}`}
            alt="Profile"
            sx={{ width: '150px', height: '150px' }}
          />

          <Stack direction="row" gap={2} my={5}>
            {' '}
            <Button
              variant="contained"
              color="error"
              sx={{ borderRadius: '100%', padding: '1.1rem' }}
              onClick={() => leave()}
            >
              <CallEnd />
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ borderRadius: '100%', padding: '1.1rem' }}
              onClick={() => {
                otherUserId.current = receivedData.calleId;
                setType('OUTGOING_CALL');
                processCall();
              }}
            >
              <Call />
            </Button>
          </Stack>
        </Card>
      )}

      {type === 'OUTGOING_CALL' && (
        <Card
          className="calling"
          elevation={5}
          sx={{
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '15px',
          }}
        >
          <h2>Calling {receivedData.name}...</h2>
          <img
            src={`https://soapboxapi.megahoot.net/profile-pictures/${receivedData.profilePic}`}
            alt="Profile"
            className="profile-pic"
          />
          <div className="spinner"></div>{' '}
          {/* Loading animation while calling */}
          <button className="end-call-button" onClick={() => leave()}>
            End Call
          </button>
        </Card>
      )}

      {type === 'WEBRTC_ROOM' && (
        <Card
          className="video-container"
          elevation={5}
          sx={{ bgcolor: '#212121' }}
        >
          <video
            autoPlay
            muted
            className="local-video"
            style={{
              width: '1px',
              height: '1px',
              border: 'none',
              opacity: '0.1',
            }}
            ref={(video) => {
              if (video && localStream) video.srcObject = localStream;
            }}
          ></video>
          <Stack direction="column" color="#fff">
            <h2>Talking {receivedData.name}</h2>
            <video
              autoPlay
              poster={`https://soapboxapi.megahoot.net/profile-pictures/${receivedData.profilePic}`}
              className="remote-video"
              ref={(video) => {
                if (video) video.srcObject = remoteStream;
              }}
              style={{
                width: '1px',
                height: '1px',
                border: 'none',
                opacity: '0.1',
              }}
            ></video>
            <Avatar
              src={`https://soapboxapi.megahoot.net/profile-pictures/${receivedData.profilePic}`}
              alt="Profile"
              sx={{ width: '200px', height: '200px' }}
            />
          </Stack>
          <div className="controls">
            <button onClick={toggleMic} className="control-button">
              {localMicOn ? <MicOutlined /> : <MicOffOutlined />}
            </button>

            <button onClick={() => leave()} className="end-call-button">
              <CallEnd />
            </button>
          </div>
        </Card>
      )}

      {type === 'INCOMING_CALL' && (
        <Container maxWidth="lg">
          <div className="incoming-call">
            <h2>{receivedData.name} is calling you...</h2>
            <Avatar
              src={`https://soapboxapi.megahoot.net/profile-pictures/${receivedData.profilePic}`}
              alt="Profile"
              sx={{ width: '200px', height: '200px' }}
            />
            <div className="action-buttons">
              <button className="accept-button" onClick={() => processAccept()}>
                <Call />
              </button>
              <button className="reject-button" onClick={() => leave()}>
                <CallEnd />
              </button>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
