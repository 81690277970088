import { ArrowBackIos } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

function IframeLoader({ iframeSrc, setTabValue }) {
  return (
    <div
      style={{
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <IconButton onClick={() => setTabValue(0)} sx={{ width: '50px' }}>
        <ArrowBackIos />
      </IconButton>
      <iframe src={iframeSrc} width="100%" height="100%" />
    </div>
  );
}

export default IframeLoader;
