import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  Paper,
  TextField,
  IconButton,
  Chip,
  Switch,
  Card,
  Dialog,
} from '@mui/material';
import {
  MarkEmailUnreadOutlined,
  EditNoteOutlined,
  SendOutlined,
  Close,
  Reply,
  Cancel,
  BackHandOutlined,
  ArrowLeftOutlined,
  OpenInFull,
  Minimize,
  CloseFullscreen,
  MoreVert,
  StartOutlined,
  StarBorder,
  CheckBoxOutlined,
  CheckBoxOutlineBlank,
  RefreshOutlined,
  MoreVertOutlined,
} from '@mui/icons-material';
import MarkdownPreview from '@uiw/react-markdown-preview';
import RichTextEditor from 'react-rte';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function Email({
  emailData,
  setEmailView,
  emailDataOutbox,
  fetchEmailData,
  fetchEmailDataOutBox,
}) {
  const theme = useTheme();
  const { userInfo } = useSelector((state) => state.userInfo);
  const [message, setMessage] = useState(RichTextEditor.createEmptyValue());
  const [selectedEmail, setSelectedEmail] = useState(null); // State for selected email
  const [composeMode, setComposeMode] = useState(false); // State to toggle between viewing and composing
  const [replyMode, setReplyMode] = useState(false); // State to toggle between viewing and composing
  const [subject, setSubject] = useState('');
  const [checked, setChecked] = useState(false);
  const [emailList, setEmailList] = useState(emailData);
  const [showMenu, setShowMenu] = useState(null);
  const [expandReply, setExpandReply] = useState(false);
  const [expandCompose, setExpandCompose] = useState(false);
  const handleSelectEmail = (email) => {
    setSelectedEmail(email);
    setReplyMode(false); // Disable compose mode when an email is selected
  };

  const handleReplyClick = () => {
    setReplyMode(true); // Enable compose mode when the compose icon is clicked
    setSelectedEmail(null); // Deselect the currently selected email
  };
  const handleComposeClick = () => {
    setComposeMode(true); // Enable compose mode when the compose icon is clicked
  };

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = () => {
    console.log(selectedEmail);
    const sendEmail = async () => {
      await axios.post('https://soapboxapi.megahoot.net/emails/mail', {
        to: selectedEmail.from_email,
        from: selectedEmail.to_email,
        subject: subject,
        message: message.toString('markdown'),
        attachments: '',
        fcmToken: selectedEmail.fcmToken,
      });
    };

    toast.promise(sendEmail, {
      pending: 'Sending Mail...',
      success: 'Mail sent Successful',
      error: 'Please try again',
    });
    // fetchEmailDataOutBox(userInfo.username);
    // fetchEmailData(userInfo.username);
    sendEmail();
    setSubject('');
    setMessage(RichTextEditor.createEmptyValue());
  };

  useEffect(() => {
    if (checked) {
      setEmailList(emailDataOutbox);
    } else {
      setEmailList(emailData);
    }
  }, [checked]);

  return (
    <Grid
      container
      sx={{
        position: 'relative',
        minHeight: '91vh',
        maxHeight: '91vh',
      }}
    >
      {/* Email List (30% of the width) */}
      <Grid
        item
        xs={2}
        sm={2}
        md={2}
        component={Paper}
        elevation={3}
        sx={{ maxHeight: '91vh', overflow: 'auto', position: 'relative' }}
      >
        <Stack direction="row" sx={{ width: '100%', height: '90vh' }}>
          <List
            sx={{
              bgcolor: '#f0f0f0',
              width: '250px',
              position: 'fixed',
            }}
          >
            <Stack direction="row" alignItems="center" sx={{ ml: '1rem' }}>
              <Button
                size="large"
                variant="contained"
                onClick={handleComposeClick}
              >
                Compose New
              </Button>
            </Stack>
            <ListItem button>Inbox</ListItem>
            <ListItem button>Sent</ListItem>
            <ListItem button>Drafts</ListItem>
          </List>
        </Stack>
      </Grid>

      {selectedEmail ? (
        <Grid item xs={10} sm={10} md={10} component={Paper} elevation={3}>
          {selectedEmail ? (
            <div>
              <Button color="inherit" onClick={() => setSelectedEmail(null)}>
                <ArrowLeftOutlined /> Go Back
              </Button>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  mb: 3,
                  bgcolor: theme.palette.primary.main,
                  textAlign: 'center',
                  padding: 1,
                  color: theme.palette.primary.contrastText,
                }}
              >
                {selectedEmail.subject || 'No Subject'}
              </Typography>
              <div style={{ padding: '1rem' }}>
                <Stack direction="row" mb="10px" alignItems="center">
                  <Typography
                    variant="subtitle2"
                    sx={{
                      // border: '0.5px solid grey',
                      bgcolor: '#f0f0f0',
                      padding: 1,
                      width: '95%',
                    }}
                  >
                    From: {selectedEmail.user_name}
                  </Typography>
                  <Button
                    disabled={checked}
                    onClick={() => setReplyMode(true)}
                    variant="contained"
                    size="medium"
                    style={{ width: '20px', height: '40px' }}
                  >
                    <Reply />
                  </Button>
                </Stack>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mb: 4,
                    // border: '0.5px solid grey',
                    bgcolor: '#f0f0f0',
                    padding: 1,
                  }}
                >
                  Subject: {selectedEmail.subject}{' '}
                </Typography>

                <Stack>
                  <MarkdownPreview
                    source={selectedEmail.message}
                    style={{
                      maxHeight: '600px',
                      overflow: 'auto',

                      padding: '1rem',
                      height: '600px',
                      backgroundColor: '#fff',
                    }}
                  />
                  {/* <Typography lineHeight={2.5} variant="body1">
                  {selectedEmail.message}
                </Typography> */}
                </Stack>
              </div>
            </div>
          ) : (
            <Typography variant="h6" sx={{ textAlign: 'center', mt: 5 }}>
              Select an email to view or click "Compose" to write a new email.
            </Typography>
          )}
        </Grid>
      ) : (
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          component={Paper}
          elevation={3}
          sx={{ maxHeight: '91vh', overflow: 'auto', position: 'relative' }}
        >
          <Stack direction="row" sx={{ width: '100%', height: '90vh' }}>
            <List sx={{ width: '100%' }}>
              <Stack sx={{ px: '1rem', my: '20px' }} direction="column">
                <TextField fullWidth placeholder="search mail" size="medium" />
                <Stack direction="row" my={2}>
                  <IconButton>
                    <CheckBoxOutlineBlank />
                  </IconButton>
                  <IconButton>
                    <RefreshOutlined />
                  </IconButton>
                  <IconButton>
                    <MoreVertOutlined />
                  </IconButton>
                </Stack>
              </Stack>
              {emailList?.map((email) => (
                <ListItem
                  onMouseEnter={() => {
                    setShowMenu(email.id);
                  }}
                  onMouseLeave={() => setShowMenu(null)}
                  button
                  key={email.id}
                  sx={{
                    borderRadius: 2,

                    bgcolor:
                      selectedEmail?.id === email.id
                        ? theme.palette.action.selected
                        : 'inherit',
                    width: '100%',
                    borderBottom: '1px solid #f0f0f0',
                  }}
                >
                  {/* <Avatar
                    src={`https://soapboxapi.megahoot.net/profile-pictures/${
                      email?.user_profilePic || email?.to_user_profilePic
                    }`}
                    sx={{ marginRight: 2 }}
                  >
                    {email.name?.slice(0, 1) || email.to_user_name?.slice(0, 1)}
                  </Avatar> */}
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <IconButton>
                      <CheckBoxOutlineBlank />
                    </IconButton>
                    <IconButton>
                      <StarBorder />
                    </IconButton>
                    <ListItemText
                      onClick={() => handleSelectEmail(email)}
                      primary={email.user_name || email?.to_user_name}
                      primaryTypographyProps={{
                        fontWeight: 'bold',
                        fontSize: '0.8rem',
                      }}
                    />
                    <ListItemText
                      onClick={() => handleSelectEmail(email)}
                      sx={{ width: '70%' }}
                      primary={`${email.message?.slice(0, 80)}...`}
                      primaryTypographyProps={{
                        fontWeight: 500,
                        fontSize: '0.8rem',
                      }}
                    />
                  </Stack>

                  {showMenu && showMenu === email.id ? (
                    <Stack direction="row" spacing={1}>
                      <IconButton
                        color="primary"
                        onClick={() => console.log('View Email')}
                      >
                        <MarkEmailUnreadOutlined />
                      </IconButton>
                      <IconButton color="secondary" onClick={handleReplyClick}>
                        <EditNoteOutlined />
                      </IconButton>
                      <IconButton color="secondary">
                        <MoreVert />
                      </IconButton>
                    </Stack>
                  ) : (
                    <Stack sx={{ width: '250px' }}>
                      {new Date(email?.created_at).toLocaleString()}
                    </Stack>
                  )}
                </ListItem>
              ))}
            </List>
          </Stack>
        </Grid>
      )}

      <Stack
        direction="row"
        sx={{
          position: 'fixed',
          right: '100px',
          bottom: '50px',
        }}
        gap={1}
      >
        {composeMode && !expandCompose && (
          <Card
            sx={{
              padding: '1.5rem',
              backgroundColor: '#fff',
              flex: 1,
              width: '500px',
            }}
            elevation={5}
          >
            <Stack sx={{ position: 'relative', width: '100%' }}>
              <Stack
                sx={{
                  position: 'absolute',

                  right: '-20px',
                  top: '-20px',
                }}
                direction="row"
              >
                <IconButton
                  variant="contained"
                  color="primary"
                  // onClick={() => {
                  //   setComposeMode(false);
                  // }}
                  size="small"
                >
                  <Minimize fontSize="10px" />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setExpandCompose(true);
                  }}
                  size="small"
                >
                  <OpenInFull fontSize="10px" />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setComposeMode(false);
                  }}
                  size="small"
                >
                  <Close fontSize="10px" />
                </IconButton>
              </Stack>
            </Stack>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              New Email{' '}
            </Typography>
            <Stack spacing={3}>
              <TextField label="To" variant="outlined" fullWidth />
              <TextField
                label="Subject"
                variant="outlined"
                fullWidth
                onChange={(e) => setSubject(e.target.value)}
              />
              {/* <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
              /> */}
              <RichTextEditor
                editorStyle={{
                  height: '200px',
                  maxWidth: '600px',
                  wordWrap: 'break-word',
                }}
                value={message}
                onChange={(value) => {
                  setMessage(value);
                }}
                placeholder="Write your message"
              />
              <Stack direction="row" gap={1} justifyContent="flex-start">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ padding: '10px', width: '100px' }}
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              </Stack>
            </Stack>
          </Card>
        )}

        {replyMode && !expandReply && (
          <Card
            sx={{
              padding: '1.5rem',
              flex: 1,
              backgroundColor: '#fff',
              width: '500px',
            }}
            elevation={5}
          >
            <Stack sx={{ position: 'relative', width: '100%' }}>
              <Stack
                sx={{
                  position: 'absolute',

                  right: '-20px',
                  top: '-20px',
                }}
                direction="row"
              >
                <IconButton
                  variant="contained"
                  color="primary"
                  // onClick={() => {
                  //   setComposeMode(false);
                  // }}
                  size="small"
                >
                  <Minimize fontSize="10px" />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setExpandReply(true);
                  }}
                  size="small"
                >
                  <OpenInFull fontSize="10px" />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setReplyMode(false);
                  }}
                  size="small"
                >
                  <Close fontSize="10px" />
                </IconButton>
              </Stack>
            </Stack>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Replying {selectedEmail?.user_name}
            </Typography>
            <Stack spacing={3}>
              <TextField
                label="To"
                variant="outlined"
                fullWidth
                disabled
                value={selectedEmail?.user_name}
              />
              <TextField
                label="Subject"
                value={subject}
                variant="outlined"
                fullWidth
                onChange={(e) => setSubject(e.target.value)}
              />
              {/* <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
              /> */}
              <RichTextEditor
                editorStyle={{
                  height: '200px',
                  maxWidth: '600px',
                  wordWrap: 'break-word',
                }}
                value={message}
                onChange={(value) => {
                  setMessage(value);
                }}
                placeholder="Write your message"
              />
              <Stack direction="row" gap={1} justifyContent="flex-start">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ padding: '10px', width: '100px' }}
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              </Stack>
            </Stack>
          </Card>
        )}
        <Dialog
          maxWidth="xl"
          open={expandReply}
          fullWidth={true}
          onClose={() => setExpandReply(false)}
        >
          <Card
            sx={{
              padding: '1.5rem',
              flex: 1,
              backgroundColor: '#fff',
              width: '100%',
              height: '100%',
            }}
            elevation={5}
          >
            <Stack sx={{ position: 'relative', width: '100%' }}>
              <Stack
                sx={{
                  position: 'absolute',

                  right: '-20px',
                  top: '-20px',
                }}
                direction="row"
              >
                <IconButton
                  variant="contained"
                  color="primary"
                  // onClick={() => {
                  //   setComposeMode(false);
                  // }}
                  size="small"
                >
                  <Minimize fontSize="10px" />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setExpandReply(false);
                  }}
                  size="small"
                >
                  <CloseFullscreen fontSize="10px" />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setReplyMode(false);
                  }}
                  size="small"
                >
                  <Close fontSize="10px" />
                </IconButton>
              </Stack>
            </Stack>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Replying {selectedEmail?.user_name}
            </Typography>
            <Stack spacing={3}>
              <TextField
                label="To"
                variant="outlined"
                fullWidth
                disabled
                value={selectedEmail?.user_name}
              />
              <TextField
                label="Subject"
                value={subject}
                variant="outlined"
                fullWidth
                onChange={(e) => setSubject(e.target.value)}
              />
              {/* <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
              /> */}
              <RichTextEditor
                editorStyle={{
                  height: '400px',
                  maxWidth: '600px',
                  wordWrap: 'break-word',
                }}
                value={message}
                onChange={(value) => {
                  setMessage(value);
                }}
                placeholder="Write your message"
              />
              <Stack direction="row" gap={1} justifyContent="flex-start">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ padding: '10px', width: '100px' }}
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Dialog>

        <Dialog
          maxWidth="xl"
          open={expandCompose}
          fullWidth={true}
          onClose={() => setExpandCompose(false)}
        >
          <Card
            sx={{
              padding: '1.5rem',
              backgroundColor: '#fff',
              flex: 1,
              width: '100%',
            }}
            elevation={5}
          >
            <Stack sx={{ position: 'relative', width: '100%' }}>
              <Stack
                sx={{
                  position: 'absolute',

                  right: '-20px',
                  top: '-20px',
                }}
                direction="row"
              >
                <IconButton
                  variant="contained"
                  color="primary"
                  // onClick={() => {
                  //   setComposeMode(false);
                  // }}
                  size="small"
                >
                  <Minimize fontSize="10px" />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setExpandCompose(false);
                  }}
                  size="small"
                >
                  <CloseFullscreen fontSize="10px" />
                </IconButton>
                <IconButton
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setComposeMode(false);
                  }}
                  size="small"
                >
                  <Close fontSize="10px" />
                </IconButton>
              </Stack>
            </Stack>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              New Email{' '}
            </Typography>
            <Stack spacing={3}>
              <TextField label="To" variant="outlined" fullWidth />
              <TextField
                label="Subject"
                variant="outlined"
                fullWidth
                onChange={(e) => setSubject(e.target.value)}
              />
              {/* <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
              /> */}
              <RichTextEditor
                editorStyle={{
                  height: '400px',
                  maxWidth: '600px',
                  wordWrap: 'break-word',
                }}
                value={message}
                onChange={(value) => {
                  setMessage(value);
                }}
                placeholder="Write your message"
              />
              <Stack direction="row" gap={1} justifyContent="flex-start">
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ padding: '10px', width: '100px' }}
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              </Stack>
            </Stack>
          </Card>
        </Dialog>
      </Stack>
    </Grid>
  );
}

export default Email;
