import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
} from '@mui/material';
import Footer from './footer';
import Header from './header';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useRouter } from '../components/hooks';

const SignUp = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [eableErrorValidation, setEnableErrorValidation] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (confirmPassword !== password) {
      return toast.error('password mismatch');
    }

    if (
      [name, email, password, username].some((e) => e == '' || e.length === 0)
    ) {
      return toast.error('Please enter all fields');
    }
    setLoading(true);
    axios
      .post('https://soapboxapi.megahoot.net/user/signup', {
        email: email,
        password: password,
        name: name,
        username: username,
      })
      .then((res) => {
        if (res.data.user) {
          toast.success(res.data.message);
          router.push('/login');
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => toast.error(err.message || 'Please try again'))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="column"
      style={{
        minHeight: '100vh',
        background: 'url(/bg.png)',
        padding: '20px',
      }}
    >
      <Header />
      <Grid
        container
        sx={{
          backgroundColor: '#ffffff1f',
          boxShadow: `3px 9px 16px hsla(0,1%,59%,.4),-3px -3px 10px hsla(0,0%,100%,.06),inset 14px 14px 26px rgba(0,0,0,.3),inset -3px -3px 15px hsla(0,9%,79%,.05)`,
        }}
        justifyContent="space-between" // Center the form when the image is hidden
        alignItems="center"
        xs={10}
        borderRadius="20px"
      >
        {/* Left Side: Poster Image - Hide on small screens */}
        <Grid
          item
          sm={6} // Display image on small screens and above
          md={6} // Medium and large screens
          display={{ xs: 'none', sm: 'block' }} // Hide image on extra-small screens (xs)
          justifyContent="flex-start"
        >
          <img
            src="/ch_poster.png"
            alt="Poster"
            style={{
              width: '100%',
              maxWidth: '700px',
              height: 'auto',
            }}
          />
        </Grid>

        {/* Right Side: Login Form */}
        <Grid
          item
          xs={12} // Full width on small screens
          sm={6} // Half width on medium and large screens
          md={4} // 4/12 width on medium screens
          padding="1rem"
        >
          <Card
            style={{
              minHeight: '60vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <CardContent>
              {/* Center the logo */}
              <Box display="flex" justifyContent="center" mb={2}>
                <img
                  src="/chathive-p.png"
                  alt="chathive"
                  width="100px"
                  height="auto"
                />
              </Box>
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                align="center"
              >
                Sign Up
              </Typography>
              <form onSubmit={handleSubmit}>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    variant="outlined"
                    type="text"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Box>

                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Username"
                    variant="outlined"
                    type="text"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    onFocus={() => {
                      setEnableErrorValidation(true);
                    }}
                    error={password !== confirmPassword && eableErrorValidation}
                  />
                </Box>
                <Box textAlign="center" mb={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    Sign Up
                  </Button>
                </Box>
                <Box textAlign="center">
                  <Typography variant="body2">
                    Already have an account? <a href="/login">Login</a>
                  </Typography>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default SignUp;
