import { io } from 'socket.io-client';

const SOCKET_URI = 'https://socketapi37464cvfgfsdhfg.megahoot.net/';

const socket = io(SOCKET_URI, {
  transports: ['websocket', 'polling'], // Ensure WebSocket transport
  autoConnect: true,
  reconnection: true, // Enable reconnection attempts
  reconnectionAttempts: 5, // Number of reconnection attempts
  reconnectionDelay: 2000, // Delay between reconnections
});

export const socketCall = io('https://testrandom.megahoot.net', {
  transports: ['websocket', 'polling'],
  autoConnect: true, // Prevent the socket from connecting automatically
  reconnection: true, // Enable reconnection attempts
  reconnectionAttempts: 5, // Number of reconnection attempts
  reconnectionDelay: 2000, // Delay between reconnections
});

export function startSocket() {
  if (!socket.connected) {
    socket.connect();
  }
}

export function stopSocket() {
  if (socket.connected) {
    socket.disconnect();
  }
}

export default socket;
