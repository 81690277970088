import { Button, Grid } from '@mui/material';
import React from 'react';

function Header() {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="column"
    >
      <Grid
        container
        sx={{
          borderRadius: '30px',
          backgroundColor: '#3d215b',
          boxShadow: `3px 9px 16px hsla(0,1%,59%,.4),-3px -3px 10px hsla(0,0%,100%,.06),inset 14px 14px 26px rgba(0,0,0,.3),inset -3px -3px 15px hsla(0,9%,79%,.05)`,
        }}
        xs={10}
        width="100%"
        direction="row"
        justifyContent="center"
      >
        <a
          href="https://www.mchathive.net"
          style={{
            color: '#fff',
            textDecoration: 'none',
            padding: '1rem',
            fontSize: '1.5rem',
            fontWeight: 'bold',
          }}
        >
          mChatHive
        </a>
      </Grid>
    </Grid>
  );
}

export default Header;
